<template>
   <div style="margin: -15px -15px;background-color: #f0f2f5;">
    <!-- tag查询框 -->
    <div class="datePicker">
      <div class="date">
        分类名称：
        <!-- @keyup.enter="searchBtn" v-model="searchName"  -->
        <a-input style="width: 180px"  placeholder="请输入" v-model="searchName" ></a-input>
      </div>
      <!-- @click="searchBtn" -->
      <a-button type="primary" style="margin-left: 30px;" @click="searchBtn">查询</a-button>
      <!-- @click="returnBtn" -->
      <a-button style="margin-left: 15px;margin-right: 40px;" @click="returnBtn">重置</a-button>
      <!-- <div>
         状态: &nbsp;
         <a-select placeholder="请选择状态"  v-model="selectStatus" style="width:124px" @change="type3Change">
           <a-select-option  value="">
             全部
           </a-select-option>
           <a-select-option  value="1">
             开启
           </a-select-option>
           <a-select-option  value="0">
            关闭
           </a-select-option>
         </a-select>
      </div> -->

      <div style="display: flex;align-items:center;margin-right: 50px;">
        <span>状态</span>: &nbsp;
        <a-radio-group  v-model="selectStatus" @change="searchOk">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="1">
            开启
          </a-radio-button>
          <a-radio-button value="0">
            关闭
          </a-radio-button>
        </a-radio-group>
      </div>

      <!-- 新建的弹窗 -->
      <a-modal v-model="addShow" title="新增" @ok="addOk" @cancel="addCancel">
        <a-form-model :model="addForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="add">
          <a-form-model-item prop="title" label="分类名称">
            <a-input v-model="addForm.title" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    
      <!-- 编辑的弹窗 -->
      <a-modal v-model="editShow" title="修改" @ok="editOk" @cancel="editCancel">
        <a-form-model :model="editForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="edit">
          <a-form-model-item prop="title" label="分类名称">
            <a-input v-model="editForm.title" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
    <!-- table -->
          <!--  -->

      <div style="background-color: #fff;padding: 20px; padding-bottom: 80px;">
        <a-button type="primary" style="margin-bottom: 20px;margin-right:3%;" @click="newlyBuilt">
        <!-- <a-icon type="plus" /> -->
          新建
        </a-button>
        <a-table :columns="columns" style="min-height: 500px;background-color: #fff;" :data-source="HeplTable" :rowKey="record => record.id"   :pagination="false"
         >
          <span slot="operate" slot-scope="text,item,index">
          <a @click="editBtn(item.id,item)" style="margin-right: 10px">修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delBtn(item.id)"
              @cancel="cancel"
          >
            <a  style="margin-right: 10px">删除</a>
            </a-popconfirm>

          </span>
          <span slot="changeJia" slot-scope="text, record,item,id">
        <!-- v-model="record.state === 1 ? true:false" -->
        <a-switch
            @change="onChange(record.id,record.state === 1?0:1,record)"
            :checked="record.state!==0"
            checked-children="是"
            un-checked-children="否"
            default-checked />
          </span>
        </a-table>
            <!-- table的分页 -->
            <div style="width:100%;margin-top: 20px;">
              <a-pagination v-model="current"
                :total="total"
                @change="tableChange"
                style="float: right;"
               />
            </div>

      </div>
   </div>
</template>
 
<script>
import moment from "moment";
import {update} from "@/utils/update";
import {getHelp,postHelp,delHelp,putHelp} from "@/service/Help";
export default {  
  data() {
    return{
      state:"",
      pageNum: 1,
      selectStatus:'',
      pageSize:10,
      current:1,
      total:40,
      addShow: false,
      editShow:false,
      rules:{
        title: [{ required: true, message: '请输入栏目标题', trigger: 'blur' }],
        code: [{ required: true, message: '请输入栏目标识', trigger: 'blur' }],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      addForm:{},
      editForm:{},
      columns:[
        {
          title: '分类名称',
          dataIndex: 'catalogue',
          // key: 'catalogue',
          // align:'center',
          // width:'8%',
        },
        {
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          scopedSlots: { customRender: 'changeJia' }
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        },
        {
          title: '操作',
          width: '20%',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      HeplTable:[
      ],
      searchName: '', //框的内容
      editId:'',
    }
  },

  created() {
    this.getHelpCenterManage()
  },

  methods: {
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      // if (status) {
      //   this.yearShowOne = true;
      // }else {
      //   this.yearShowOne = false;
      // }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
    //   this.yearShowOne = false;
    //   this.year = value;
    //   this.page_no = 1
    //   if(this.year){
    //     this.year = moment(this.year).format('YYYY')
    //   }
    // //  刷新
    //   this.getList()
    },
    // 新建表单
    newlyBuilt() {
      this.addShow = true;
      // this.getIndex(this.index)
      // this.getVideoIndex(this.VideoIndex)
      // console.log("看看新建的form", this.form)
      // this.buttonType = true
      // this.startTime = (moment().format('YYYY-MM-DD'))
    },

    // 新建
    async addOk() {
      this.$refs.add.validate(async valid => { // 表单校验
        if(valid){
          const response = await postHelp(this.addForm.title)
          // console.log(response)
          if (response.code === 0) {
            // 刷新首页数据
            this.getHelpCenterManage()
            this.$message.success('新增成功')
            this.addShow = false;
          } else {
            // this.$message.warning("填写分类名称重复",response.message)
            this.$message.warning("填写分类名称重复")
          }
          this.$refs.add.resetFields()
         } else {
           this.$message.error("请填写完整")
        }
      })

    },
    editOk(){
      this.$refs.edit.validate(async valid => { // 表单校验
        if(valid){
          const Info = await putHelp(this.editForm.title,this.editId,"")
            if (Info.code === 0){
              this.getHelpCenterManage()
              this.$message.success('修改成功')
            }else {
              this.$message.error('操作失败' + Info.message)
            }
            this.editShow = false;
            this.$refs.edit.resetFields()
        } else {
         this.$message.error("请填写完整")
        }
      })
    },
    // 弹窗取消
    addCancel(){
      console.log("取消")
      this.$refs.add.resetFields()
    },
    editCancel(){
    this.$refs["edit"].clearValidate();
    },
    // table列表
    async getHelpCenterManage(){
      const response = await getHelp(this.searchName,this.state,this.pageNum,this.pageSize)
      if (response.code === 0) {
        this.HeplTable = response.data.list
        this.total = response.data.total
      } else if(response.code === 0 &&  response.data.list.length == 0){
          this.pageNum = 1
          const res = await getHelp(this.searchName,this.state,this.pageNum,this.pageSize)
          this.HeplTable = res.data.list
          this.total = res.data.total
      }else{
        this.$message.warning(response.message)
      }
    },
    // 状态切换查询
    // type3Change(value) {
    //   console.log(value)
    //   switch (value) {
    //     // 全部
    //     case '':
    //       // this.getFeedback(1,'METTING',this.searchType)
    //       this.state = ""
    //       this.pageSize = 10
    //       this.pageNum = 1
    //       this.current = 1
    //       this.getHelpCenterManage()
    //       break;
    //     // 缺陷
    //     case '1':
    //       // this.getFeedback(1,'METTING',this.searchType)
    //       this.state = 1
    //       this.pageSize = 10
    //       this.pageNum = 1
    //       this.current = 1
    //       this.getHelpCenterManage()
    //       break;
    //     // 需求
    //     case '0':
    //       // this.getLogData(1,'CONAULTATION',this.searchType)
    //       this.state = 0
    //       this.pageSize = 10
    //       this.pageNum = 1
    //       this.current = 1
    //       this.getHelpCenterManage()
    //       break;
    //   }
    // },

    // 状态框查询
    searchOk(e) {
      console.log(e.target.value)
      switch (e.target.value) {
        // 全部
        case '':
          this.state = ""
          this.pageSize = 10
          this.pageNum = 1
          this.current = 1
          this.getHelpCenterManage()
          break;
        // 缺陷
        case '1':
          this.state = 1
          this.pageSize = 10
          this.pageNum = 1
          this.current = 1
          this.getHelpCenterManage()
          break;
        // 需求
        case '0':
          this.state = 0
          this.pageSize = 10
          this.pageNum = 1
          this.current = 1
          this.getHelpCenterManage()
          break;
      }
    },
    // 搜索框查询
    searchBtn() {
      // 点击查询的时候不要管页码的参数
      // this.pagination.pageNum = 1;
      // this.pagination.pageSize = 10;
      // this.pagination.current = 1
      // console.log(this.searchName)
      this.pageNum = 1
      this.getHelpCenterManage()
    },
    // 分页查询
    tableChange(e){
      this.pageNum = e
      this.getHelpCenterManage()
    },
    // 重置
    returnBtn() {
      this.searchName = ''  //输入框
      this.current = 1  //默认显示
      this.pageSize = 10  //行数
      this.pageNum = 1
      this.state = ""  //状态
      this.selectStatus = ''
      this.getHelpCenterManage('', '', '', this.PpageNum,this.PpageSize)
    },
    // 删除
   async delBtn(id){
      const response = await delHelp(id)
      if (response.code == 0) {
        this.getHelpCenterManage()
        this.$message.success('删除成功')
      } else {
        this.$message.warning(response.message)
      }
    },

   async onChange(id, open,record) {
      console.log("看看id和状态",id, open,record)
      const Info = await putHelp("",id,open)
      if (Info.code === 0){
        this.getHelpCenterManage()
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败' + Info.message)
      }
   },

    // 点击修改
    editBtn(id,item){
     this.editShow = true
    //  console.log("看看item",item.catalogue)
     this.$set(this.editForm, 'title', item.catalogue)
     this.editId = id
    },

    // 取消
    cancel(){}
    },

}
</script>

<style lang="scss" scoped>
.datePicker{
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  .status{
    float: right;
    position: absolute;
    right: 50px;
  }
}


</style>